<template>
  <div>
    <div v-if="isLoading">
      <OtherLoading />
    </div>
    <div>
      <div>
        <h1 class="mr-sm-4 header-tablepage">PRIVACY POLICY</h1>
      </div>
      <div class="title-tabs mt-3">Privacy Policy Setting Detail</div>
      <div class="pr-3 pl-3 bg-white">
        <b-row>
          <b-col>
            <TextEditorsTiny
              v-model="form.template"
              :value="form.template"
              @onDataChange="(val) => (form.template = val)"
              textFloat=""
              placeholder="PRIVACY POLICY"
            />
          </b-col>
        </b-row>
      </div>
      <FooterAction @submit="UpdatePrivacyPolicy" routePath="/setting" />
    </div>
  </div>
</template>

<script>
import TextEditorsTiny from "@/components/inputs/TextEditorsTiny";
import OtherLoading from "@/components/loading/OtherLoading";
export default {
  components: {
    TextEditorsTiny,
    OtherLoading,
  },
  data() {
    return {
      form: {
        template: null,
        version: 0,
        status: 0,
      },
      updatePolicy: {},
      isLoading: true,
      detailPolicy: {},
    };
  },
  async created() {
    await this.getDetailPolicy();
  },
  methods: {
    async getDetailPolicy() {
      await this.$store.dispatch("getDetailPolicy");
      const data = this.$store.state.setting.stateDetailPolicy;
      if (data.result === 1) {
        this.form.template = data.detail;
        this.isLoading = false;
      }
    },
    async UpdatePrivacyPolicy() {
      await this.$store.dispatch("UpdatePrivacyPolicy", this.form);
      const data = this.$store.state.setting.stateUpdatePrivacyPolicy;
      if (data.result === 1) {
        this.successAlert().then(() => {
          this.$router.push(`/setting`);
        });
        this.updatePolicy = data.detail;
        this.isLoading = false;
      } else {
        this.errorAlert(data.message);
      }
    },
    closeAutomation() {
      this.$router.push("/setting");
    },
  },
};
</script>
